@import '@assets/components_base';

.marker {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $blue;
  border: rem-property(2px) solid $blue;
  transition: all 0.3s;
  outline: none;
  padding: 0;
  z-index: 0;
  @include md_ {
    width: rem-property(20px);
    height: rem-property(20px);
  }

  &.active {
    background: $white;
    width: 22px;
    height: 22px;
    z-index: 1;

    @include md_ {
      width: rem-property(22px);
      height: rem-property(22px);
    }
  }
}
