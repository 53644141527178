@import '@assets/components_base';

.root {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: $black-border;

  position: relative;
  @include hover {
    background: var(--color-white-transparent);
  }

  &[data-state='open'] {
    background: var(--color-grey-light);

    .icon {
      transform: rotate(-90deg) translateY(-5px);
    }

    .addressTitle {
      padding-bottom: calc(var(--standard-margin) * 0.5);
      border-bottom: 1px solid var(--color-grey);
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .button {
    display: block;
    width: 100%;
    background: none;
    border: none;
    padding: calc(var(--standard-margin) * 0.5);
    color: var(--color-primary);
    cursor: pointer;
    text-align: left;

    @include hover {
      opacity: 0.4;
    }
  }

  .title {
    // margin-bottom: 1em;
    max-width: calc(100% - #{rem-property(25px)});
    margin-bottom: 1em;
  }

  .addressTitle {
    > * {
      max-width: calc(100% - #{rem-property(25px)});
    }
  }

  p {
    margin: 0;
  }

  .icon {
    position: absolute;
    top: calc(var(--standard-margin) * 0.5);
    right: calc(var(--standard-margin) * 0.5);
    width: rem-property(20px);
    height: auto;
    padding-top: 0.24rem;
    stroke: var(--color-primary);
  }

  .addressBlock {
    display: none;
    padding: 0 calc(var(--standard-margin) * 0.5)
      calc(var(--standard-margin) * 0.5);

    &.active {
      display: block;
    }

    .addressLine {
      margin-bottom: 1em;
    }
  }
}
