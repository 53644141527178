@import '@assets/components_base';

.root {
  padding: var(--standard-margin) var(--standard-margin) 0;
  margin-bottom: var(--standard-margin);

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 var(--standard-margin);

    @include md_ {
      grid-template-columns: repeat(6, 1fr);
      background: var(--color-grey-light);
      border-radius: rem-property(20px);
    }
  }

  .col {
    position: relative;
    display: flex;
    flex-direction: column;

    @include md_ {
      grid-column: span 2;
    }

    &::after {
      @include md_ {
        content: ('');
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(-1 * var(--standard-margin) * 0.5);
        border-right: 1px solid var(--color-white);
      }
    }

    &:last-child {
      &::after {
        @include md_ {
          border-right: none;
        }
      }
    }
  }

  .card {
    background: var(--color-grey-light);
    border-radius: rem-property(20px);
    margin-bottom: var(--standard-margin);

    @include md_ {
      background: none;
      border-radius: 0;
      margin-bottom: 0;
      padding: var(--standard-margin) calc(0.5 * var(--standard-margin));
    }

    &.card1 {
      @include md_ {
        padding-left: var(--standard-margin);
      }
    }

    &.card3 {
      @include md_ {
        padding-right: var(--standard-margin);
      }
    }
  }

  .hr {
    display: none;

    @include md_ {
      display: block;
      grid-column: 1 / 7;
      border-bottom: 1px solid var(--color-white);
    }
  }

  .titleLarge {
    margin: var(--standard-margin) 0;

    @include md_ {
      padding: 0 var(--standard-margin);
    }
  }

  .titleSmall {
    color: var(--color-grey);
  }

  .detail {
    margin: var(--standard-margin) 0;
    padding: 0;

    @include md_ {
      padding: 0 calc(var(--standard-margin) * 0.5);
    }
  }
  .detailLarge {
    margin: rem-property(10px) 0;
  }

  .total {
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include md_ {
      margin-top: rem-property(65px);
      margin-bottom: rem-property(65px);
    }
  }

  .leadtime {
    position: relative;
    margin-bottom: rem-property(65px);

    @include md_ {
      padding-top: rem-property(65px);
    }
    &::before {
      content: ('');
      position: absolute;
      top: 0;
      left: calc(var(--standard-margin) * -0.5);
      right: 0;
      border-top: 1px solid var(--color-white);
    }
  }
}
