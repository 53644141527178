@import '@assets/components_base';

.root {
  //   height: 100%;
  //   margin: 0 auto;
  //   max-width: 2460px;
}

.container {
  position: relative;
  overflow: hidden;
}

.slider {
  display: none;
  margin-left: var(--standard-margin);

  @include md_ {
    margin-left: 0;
  }
}

.show {
  display: block;
}

.root {
  .arrowLeft {
    top: unset;
    bottom: var(--standard-margin);
    left: var(--standard-margin);
    display: none;

    @include md_ {
      left: calc(var(--standard-margin) + #{rem-property(100px)});
      display: block;
    }
  }

  .arrowRight {
    top: unset;
    bottom: var(--standard-margin);
    display: none;

    @include md_ {
      display: block;
    }
  }
}

.slide {
  transition: opacity var(--standardTransitionTime);
}

.activeSlide {
  opacity: 1;
}

.nonActiveSlide {
  opacity: 0.7;
}
