@import '@assets/components_base';
.root {
  .contentContainer {
    border-top: $standard-border;
    margin: 0 var(--standard-margin) rem-property(244px);

    @include md_ {
      display: flex;
      gap: var(--standard-margin);
    }
  }

  &.disabled {
    .AccordionTrigger {
      cursor: unset;
    }
    .AccordionTrigger:hover {
      opacity: 1;
    }
  }

  .title {
    flex-basis: percentage(2 / 6);
    padding-top: rem-property(30px);

    margin: 0;
  }

  .questions {
    flex-basis: percentage(4 / 6);
  }

  .faq {
    padding-right: calc(100% - #{rem-property(680px)});
    border-top: $standard-border;
    padding-top: rem-property(30px);
    margin-bottom: rem-property(30px);

    &:nth-child(1) {
      border-top: 0;
    }
  }

  &.faqBlackBorder {
    .contentContainer,
    .faq {
      border-top: $black-border;
    }

    .faq:nth-child(1) {
      border-top: none;
    }
  }

  .detail {
    @include content-type-2;
  }

  button,
  h3 {
    all: unset;
  }

  .AccordionRoot {
  }

  .AccordionItem {
    overflow: hidden;
    margin-top: 1px;
  }

  .AccordionItem:first-child {
    margin-top: 0;
  }

  .AccordionItem:focus-within {
    position: relative;
    z-index: 1;
  }

  .AccordionHeader {
    display: flex;
  }

  .AccordionTrigger {
    flex: 1;
    display: flex;
    cursor: pointer;
  }

  .AccordionTrigger:hover {
    opacity: $default-opacity;
  }

  .AccordionContent {
    overflow: hidden;

    p:last-child {
      margin-bottom: 0;
    }
  }
  .AccordionContent[data-state='open'] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  .AccordionContent[data-state='closed'] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  .AccordionContentText {
  }

  .AccordionChevron {
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  .AccordionTrigger[data-state='open'] > .AccordionChevron {
    transform: rotate(180deg);
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
}
