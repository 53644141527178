@import '@assets/components_base';

.root {
  width: 100%;
  position: relative;
  z-index: 0;
  min-height: 100vh;

  @include md_ {
    height: 100vh;

    scroll-snap-align: start;
    overflow: hidden;
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hidden {
  display: none;
}

.modalActive {
  z-index: 1;
}

.fade {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @include md_ {
    display: none;
  }
}

.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.videoInner {
  height: 100%;
  width: 100%;

  :global {
    .video-react-fluid.video-react {
      background: transparent;
      height: 100%;
      width: 100%;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    button.video-react-button {
      display: none;
    }
  }
}

.poster {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.isPlaying {
  display: none;
}
