@import '@assets/components_base';

.root {
  padding: var(--standard-margin);
  display: flex;
  flex-direction: column;
  height: 100%;

  .image {
    margin-bottom: var(--standard-margin);
    @include md_ {
      height: rem-property(360px);
      width: rem-property(360px);
      margin: 0 auto var(--standard-margin);
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }

  .titleContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .title {
    width: rem-property(300px);
    margin: 0;

    @include md_ {
      width: rem-property(210px);
    }
  }

  .colors {
    display: flex;
    margin-top: rem-property(3px);

    .white {
      background: $white;
      display: block;
      width: rem-property(21px);
      height: rem-property(21px);
      border-radius: 50%;
      border: 1px solid $black;
      margin-right: rem-property(5px);
    }

    .black {
      background: $black;
      display: block;
      width: rem-property(21px);
      height: rem-property(21px);
      border-radius: 50%;
      border: 1px solid $black;
    }
  }

  .detail {
    p {
      margin: var(--standard-margin) 0;
    }
  }

  .orderContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: auto;
  }
}
