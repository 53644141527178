@import '@assets/components_base';

.root {
  margin-bottom: rem-property(150px);

  .loreTwoPerformanceSections {
    @include md_ {
      button > svg {
        top: 50%;
      }
    }
  }

  &.lightTheme {
    color: $white;
  }
}

[data-keen-slider-moves] {
  .slide {
    cursor: grabbing;
  }
}

.slide {
  position: relative;

  cursor: grab;
}

.backgroundImage {
  padding: var(--standard-margin) 0;
  height: 140vw;

  @include md_ {
    height: rem-property(824px);
    padding: var(--standard-margin) 0 0;
  }
}

.backgroundImageInner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: rem-property(20px);

  // height: calc(100vh - var(--standard-margin) * 2);
}
.backgroundImagePicture {
  border-radius: rem-property(20px);
}

.videoInner {
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    border-radius: rem-property(20px);
  }

  .poster {
    &.isPlaying {
      display: none;
    }
  }

  :global {
    .video-react {
      width: 100%;
      height: 100%;
      padding-top: unset;
    }

    .video-react-big-play-button {
      display: none;
    }
  }
}

.slideContent {
  position: absolute;
  top: var(--standard-margin);
  left: var(--standard-margin);
  right: var(--standard-margin);
  bottom: var(--standard-margin);
  z-index: 1;
  // pointer-events: none;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3fr min-content min-content;

  @include md_ {
    grid-template-rows: 1fr auto 1fr min-content;
  }
}

.detailContainer {
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;

  @include md_ {
    display: block;
    margin-bottom: 0;
  }
}

.readMore {
  align-self: flex-end;
  background-color: $grey;
  border-color: $grey;
  @include md_ {
    align-self: auto;
  }
}

.citation {
  grid-row: 3 / 4;
  margin-top: auto;
}

.citationDetail {
  grid-row: 4 / 5;
}

.fade {
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  z-index: 0;
  pointer-events: none;
  width: 110%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 0 var(--standard-margin);

  @include md_ {
    margin: 0 var(--standard-margin) 0 auto;
    display: flex;
    width: calc(35.2% - var(--standard-margin));
  }
}
