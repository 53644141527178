@import '@assets/components_base';

.root {
  position: relative;
  padding: 0 var(--standard-margin);
  z-index: 0;

  .backgroundContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(
      160deg,
      var(--color-grey-mid) 0%,
      var(--color-white) 25%,
      var(--color-white) 30%,
      var(--color-white) 35%,
      var(--color-grey-mid) 60%,
      var(--color-grey-mid) 70%
    );
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // align-items: flex-end;

    &::after {
      content: ('');
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: linear-gradient(
        160deg,
        var(--color-pink-opacity) 75%,
        var(--color-pink) 110%
      );
      z-index: -1;
    }
  }

  .inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 var(--standard-margin);
    @include md_ {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .title {
    margin: 185px auto rem-property(61px);
    text-align: center;

    @include md_ {
      grid-column: 2 / 6;
      margin-top: rem-property(285px);
      margin-bottom: rem-property(61px);
    }
  }

  .button {
    display: flex;
    margin: 0 auto rem-property(100px);

    @include md_ {
      grid-column: 1 / -1;
    }

    .buttonInner {
      padding-left: rem-property(40px);
      padding-right: rem-property(40px);
    }
  }

  .image {
    margin-bottom: rem-property(192px);

    @include md_ {
      grid-column: 2 / 6;
    }
  }

  .detail {
    @include md_ {
      grid-column: 1 / 5;
      margin-bottom: rem-property(90px);
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 var(--standard-margin);
    @include md_ {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .col {
    position: relative;

    border-bottom: 1px solid var(--color-primary);
    padding: 32px 0;
    margin-bottom: var(--standard-margin);
    @include md_ {
      grid-column: span 2;
      padding: 0 calc(var(--standard-margin) * 0.5) 0;
      border-bottom: none;
      margin-bottom: 0;
    }

    &::after {
      @include md_ {
        content: ('');
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(-1 * var(--standard-margin) * 0.5);
        border-right: 1px solid var(--color-white);
      }
    }

    .row1 {
      @include md_ {
      }
    }

    &:first-child {
      @include md_ {
        padding: 0 calc(var(--standard-margin) * 0.5) 0 var(--standard-margin);
      }
    }
    &:last-child {
      border-bottom: none;
      @include md_ {
        padding: 0 var(--standard-margin) 0 calc(var(--standard-margin) * 0.5);
      }
      &::after {
        @include md_ {
          border-right: none;
        }
      }
    }
  }

  .titleContainer {
    display: flex;
    align-items: center;
    @include md_ {
      align-items: baseline;
    }
    .icon {
      position: relative;
      height: 32px;
      width: 32px;
      margin-right: 40px;
      @include md_ {
        height: rem-property(32px);
        width: rem-property(32px);
        margin-right: rem-property(40px);
      }
    }
  }
}
