@import '@assets/components_base';

.root {
  padding: 0 var(--standard-margin);
  margin-bottom: calc(rem-property(150px) - var(--standard-margin));

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    background: var(--color-blue);
    border-radius: rem-property(20px);
    gap: 0 var(--standard-margin);
    color: var(--color-white);
    @include md_ {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .title {
    padding: calc(2 * var(--standard-margin)) var(--standard-margin);
    margin: 0;
    @include md_ {
      grid-column: 1 / 3;
      padding: var(--standard-margin) var(--standard-margin) rem-property(120px);
    }
  }

  .detail {
    padding: 0 var(--standard-margin) calc(2 * var(--standard-margin));

    @include md_ {
      padding: 0 var(--standard-margin) var(--standard-margin);
      grid-column: 1 / 4;
    }

    p {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .stepContainer {
    padding: calc(2 * var(--standard-margin)) var(--standard-margin);
    @include md_ {
      grid-column: 1 / 5;
      display: flex;
      padding: 0 var(--standard-margin) rem-property(120px);
    }
  }

  .step {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid $white;
    @include md_ {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-basis: 25%;
      padding-right: calc(0.5 * var(--standard-margin));
      margin-right: calc(0.5 * var(--standard-margin));
      border-right: rem-property(2px) solid $white;
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &:first-child {
      padding-top: 1rem;
      border-top: 2px solid $white;
      @include md_ {
        border-left: rem-property(2px) solid $white;
        padding-left: calc(0.5 * var(--standard-margin));
        padding-top: 0;
        border-top: none;
      }
    }

    &:last-child {
      @include md_ {
        margin-right: 0;
      }
    }

    p {
      margin: 0;
    }
  }

  .stepTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    span {
      display: block;
    }

    h2 {
      margin: 0;
    }
  }
  .stepDetail {
    margin-top: auto;
  }

  .arrow {
    display: block;
    position: relative;
    width: 40px;
    height: auto;

    @include md_ {
      display: block;
      position: relative;
      width: rem-property(50px);
      height: auto;
    }
  }
}
