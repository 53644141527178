@import '@assets/components_base';

.button {
  appearance: none;
  outline: none;
  border: none;
  background: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}
.root {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
  right: var(--standard-margin);
  z-index: 1;
}

.rootLeft {
  left: var(--standard-margin);
  right: unset;
}

.disabled {
  opacity: 0.5;
}
