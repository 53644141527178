@import '@assets/components_base';

.root {
  position: relative;
  padding: 0 var(--standard-margin);
  margin-bottom: rem-property(150px);

  .title {
    margin-bottom: calc(var(--standard-margin) * 2);

    @include md_ {
      margin-bottom: 1em;
    }
  }

  .addressContainer {
    display: grid;
    margin-bottom: calc(2 * var(--standard-margin));

    @include md_ {
      margin-bottom: var(--standard-margin);

      grid-template-columns: repeat(6, 1fr);
      gap: var(--standard-margin);
    }
  }

  form {
    @include md_ {
      grid-column: 1 / 3;
    }
  }

  .inputContainer {
    position: relative;
  }

  .input {
    @include content-type-2;
    display: block;
    width: 100%;
    background: var(--color-primary);
    color: var(--color-white);
    border-radius: rem-property(30px);
    padding: rem-property(12px) rem-property(19px);
    margin-bottom: 0.5rem;

    @include md_ {
      border-radius: rem-property(20px);
      margin-bottom: 0;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem-property(20px);
    width: auto;
    padding: 0;
    border: none;

    @include hover {
      opacity: $default-opacity;
    }
  }

  .currentLocation {
    @include content-type-2;
    color: var(--color-blue);
    background: none;
    border: none;

    padding: 0;
    justify-content: flex-start;

    @include md_ {
      grid-column-start: 3;
      margin: auto auto auto 0;
    }

    @include hover {
      opacity: 0.8;
      background: none;
      border: none;
      outline: none;
    }
  }

  .grid {
    display: grid;
    margin-bottom: rem-property(150px);
    @include md_ {
      grid-template-columns: repeat(6, 1fr);
      gap: 0 var(--standard-margin);
    }
  }

  .fittersCol {
    grid-row: 2 / 3;

    @include md_ {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      grid-column: span 2;
      grid-row: 1 / 2;
    }
  }

  .suggestContainer {
    text-align: center;

    @include md_ {
      text-align: left;
    }
    .suggestTitle {
      margin-bottom: rem-property(40px);

      @include md_ {
        margin-bottom: rem-property(20px);
      }
    }

    .suggestButton {
      padding-left: rem-property(50px);
      padding-right: rem-property(50px);
    }
  }

  .fittersContainer {
    border: $black-border;
    max-height: rem-property(700px);
    overflow-y: auto;
    margin-bottom: rem-property(40px);

    @include md_ {
      margin-bottom: auto;
      max-height: m#{i}n(#{rem-property(1133px)}, 61.45vw);
    }
  }

  .mapCol {
    grid-row: 1 / 2;
    margin-bottom: calc(2 * var(--standard-margin));

    @include md_ {
      grid-column: span 4;
      margin-bottom: 0;
    }
  }

  .map {
    aspect-ratio: 1 / 1;
    background: var(--color-grey);
    width: 100%;
  }
}
